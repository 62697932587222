export const translations = {
    "network_title": 'MTN',
    "profile_header": "Mon Profil",
    "profile_airtime_won": "MINUTES D’APPEL",
    "profile_play": "JOUER",
    "profile_currency_acronym": 'R',
    "profile_total_season_points_header": " TOTAL DES POINTS DE LA SAISON ",
    "profile_rounds_entered_header": "TOURS ENREGISTRÉS ",
    "profile_top_hundred_header": " 20 PREMIERS AU CLASSEMENT ",
    "profile_top_two_hundred_result": "{0}",
    "profile_auto_team_builder_button": " Générateur d'équipe auto",
    "profile_auto_team_builder_popup_one": " Salut entraîneur!",
    "profile_auto_team_builder_popup_two": " Trop occupé pour faire un choix difficile cette semaine ? Demandez l'aide de votre entraîneur adjoint en utilisant le Générateur d'équipe ",
    "profile_top_managers_header": " MEILLEURS MANAGERS ",
    "profile_my_top_picks_header": "MES LISTES DE CHOIX",
    "footer_profile_icon": "Profil",
    "footer_top_picks_icon": " Ma Liste de Choix",
    "footer_play_icon": "Jouer",
    "footer_my_rounds_icon": "Mes Tours ",
    "footer_trophy_icon": "Resultat ",
    "sidebar_menu_how_to_play_icon": "comment jouer ",
    "sidebar_menu_trophy_icon": " prix ",
    "sidebar_menu_terms_conditions_icon": " t & c ",
    "sidebar_menu_more_games_title": "plus de jeux",
    "sidebar_menu_rugby_icon" : " fantasy rugby ",
    "sidebar_menu_predictor_icon": "prédicteur ",
    "top_picks_header": " Mes Listes de Choix ",
    "auto_team_builder_header_banner" : `Générateur d'équipe`,
    "auto_team_builder_header_description": " Trop occupé pour faire vos sélections MTN Fantasy Football?",
    "auto_team_builder_how_it_works_header": " Comment ça marche ",
    "auto_team_builder_how_it_works_bullet_point_one": " Inscrivez-vous pour la sélection automatique des équipes",
    "auto_team_builder_how_it_works_bullet_point_two": " Nous allons utiliser la fonction de sélection automatique pour enregistrer une équipe dans tous les tours de la plateforme",
    "auto_team_builder_how_it_works_bullet_point_three": " Chaque semaine, nous sélectionnerons au hasard 5 joueurs qui composeront votre équipe et que nous inscrirons pour vous",
    "auto_team_builder_how_it_works_bullet_point_four": " Vous serez inscrit à tous les tours ou uniquement au tour immédiat",
    "auto_team_builder_how_it_works_bullet_point_five": " Si vous voulez modifier votre sélection, vous pouvez toujours changer l'équipe avant le début du tour",
    "auto_team_builder_selection_button": " Ajouter la sélection automatique d'équipe",
    "auto_team_builder_selection_button_remove": " Supprimer la sélection automatique d’équipe",
    "auto_team_builder_back_button": " Retour",
    "settings_header": ' Paramètres ',
    "settings_change_username_button": `Changer le nom d'utilisateur`,
    "settings_change_notifications_button": ' Notifications ',
    "settings_change_cancel_package_button": ' Annuler le forfait',
    "username_header": `Changer votre nom d'utilisateur`,
    "username_explanation_content": `Veuillez saisir le nouveau nom d'utilisateur avec lequel vous souhaitez être identifié sur nos classements`,
    "username_current_username": `Nom d'utilisateur actuel:`,
    "username_input_placeholder": `Saisir un nouveau nom d'utilisateur`,
    "username_min_max_character" : `Le nom d'utilisateur doit être un mélange de 6 à 14 caractères alphabétiques`,
    "username_change_username_button":`Changer le nom d'utilisateur`,
    "username_back_button": 'Retour',
    "username_invalid" : `Erreur : Nom d'utilisateur non valide`,
    "notifications_header": ' Notifications',
    "notifications_manage": 'Gérer vos notifications',
    "notifications_checkbox": 'Messages de service',
    "notifications_service_updates_message": 'Je souhaiterais recevoir des mises à jour sur votre service ou sur toute promotion que vous organisez',
    "notifications_save_button": 'Enregistrer',
    "notifications_back_button" : 'Retour',
    "unsubscribe_header": 'Annuler le forfait',
    "unsubscribe_status": 'Vous êtes actuellement inscrit à MTN Fantasy League',
    "unsubscribe_confirm_unsubscription": ' Je confirme que je souhaite annuler mon forfait avec MTN Fantasy League',
    "unsubscribe_confirm_button": 'Confirmer',
    "unsubscribe_back_button" : 'Retour',
    "upcoming_rounds_header_one" : "Tours:",
    "upcoming_rounds_header_two" : " Participer et Gagner",
    "upcoming_rounds_play_button": "Jouer",
    "upcoming_fixtures_header_title": " Les prochaines rencontres ",
    "upcoming_fixtures_view_games_title": "voir les jeux",
    "my_results_header_title": " Mes Résultats ",
    "my_results_play_circle": "JOUER",
    "my_results_winnings_header": "Temps d'appel total gagné",
    "my_results_airtime_won_vertical_tag" : 'VAINQUEUR',
    "my_results_airtime_won_currency_acronym": 'R',
    "my_results_airtime_won_label": `Temps d'appel`,
    "my_results_table_round_column_header": 'Tour',
    "my_results_table_position_column_header": 'Position',
    "my_results_table_points_column_header": 'Points',
    "my_results_table_airtime_column_header": `Temps d'appel`,
    "round_leaderboard_header_title": "Tableau de bord",
    "round_leaderboard_table_position_column_header": 'Position',
    "round_leaderboard_table_user_name_column_header": `Nom d'utilisateur`,
    "round_leaderboard_table_highest_rank_column_header": `Le rang le plus élevé`,
    "round_leaderboard_table_total_points_column_header": `Total des points`,
    "round_leaderboard_my_position_circle": `Ma Position`,
    "how_to_play_header": 'Comment jouer:',
    "how_to_play_general_header": 'Général ',
    "how_to_play_general_info_point_one": `Les compétitions Fanclash sont un jeu d'adresse et requièrent une certaine compétence et une connaissance des informations sur le sport professionnel ainsi que des règles Fanclash de votre part pour accumuler des points fantasy;`,
    "how_to_play_general_info_point_two" : `Les points Fantasy sont accumulés grâce aux performances d'athlètes réels lors de plusieurs rencontres sportives;`,
    "how_to_play_general_info_point_three": `Chaque équipe de fantasy doit inclure des athlètes réels, de sorte que chaque participation à une compétition Fanclash doit être composée de joueurs tirés d'au moins une rencontre sportive réelle.`,
    "how_to_play_general_info_point_four": `Fanclash interdit strictement de remporter une compétition sur la seule base de la performance individuelle d'un athlète;`,
    "how_to_play_general_info_point_five": `Le site vous empêchera automatiquement d'enregistrer une sélection qui enfreint l'une de ces restrictions;`,
    "how_to_play_general_info_point_six": `Il ne sera pas procédé à des échanges de joueurs, ni à des mouvements supplémentaires, ni au remplacement de joueurs blessés ou sur le banc de touche;`,
    "how_to_play_contest_rules_header": `Règlement de la compétition`,
    "how_to_play_contest_rules_point_one": `Chaque compétition exige la sélection d'un attaquant, deux milieux de terrain, une équipe défensive (constituée de joueurs répertoriés comme joueurs défensifs par le fournisseur de statistiques dans une équipe spécifique) et un gardien de but..`,
    "how_to_play_contest_rules_point_two": `La liste des joueurs est constituée de joueurs pour lesquels le prestataire de statistiques dispose des statistiques dans cette ligue particulière et qu'il considère comme éligibles pour les matchs faisant partie de la compétition`,
    "how_to_play_contest_rules_point_three": `Tous les joueurs exclus d'une équipe par le fournisseur de statistiques (joueurs "manquants") ne pourront être ajoutés qu'une fois que le fournisseur de statistiques aura reconnu et/ou ajouté le nouveau joueur et Fanclash ne pourra en aucun cas être tenu responsable de la non-participation d'un tel joueur à une compétition`,
    "how_to_play_contest_rules_point_four": `Une fois la compétition lancée, les salaires des joueurs ne changent pas, mais ils peuvent fluctuer d'une compétition à l'autre en fonction des performances et de la popularité du joueur.`,
    "how_to_play_contest_rules_point_five": `Chaque compétition spécifie une date après laquelle les participations ne peuvent plus être ajoutées, modifiées ou annulées.`,
    "how_to_play_contest_rules_point_six": `Les résultats et vainqueurs de la compétition seront basés sur le total des points marqués dans l'équipe de chaque participant (résumé des points ci-dessous) pendant la durée de la compétition`,
    "how_to_play_contest_rules_point_seven": `Si un match faisant partie de la compétition est reprogrammé en dehors des dates initialement prévues pour le début et la fin de la compétition, les joueurs participant à ces matches ne gagneront aucun point.`,
    "how_to_play_edit_lineup_header": `Modifier une équipe`,
    "how_to_play_edit_lineup_info": `Vous pouvez modifier votre équipe à n'importe quel moment jusqu'au début de la compétition en cliquant sur l'option de modification de la vue d'entrée. CEPENDANT, dès que la compétition commence, votre équipe ne sera plus modifiable et vous pourrez voir l'équipe de vos adversaires.`,
    "how_to_play_entry_fees_header": `Frais d'inscription`,
    "how_to_play_entry_fees_info": `Il n'y a pas de frais d'inscription pour les compétitions spécifiques. Les membres du service Fanclash auront la possibilité de participer aux compétitions hebdomadaires et quotidiennes tant que leur abonnement au contenu sera actif.`,
    "how_to_play_entry_cancellation_header": `Annulation d’une inscription`,
    "how_to_play_entry_cancellation_info": `Fanclash se réserve le droit d'annuler tout jeu avant le début prévu de celui-ci. Lorsque plusieurs jeux ayant les mêmes conditions d'entrée (comme, mais sans s'y limiter, les jeux réels, la taille prévue de la cagnotte, etc.) sont susceptibles d'être annulés parce que le nombre de participants est trop faible, Fanclash peut éviter l'annulation en combinant les cagnottes de ces jeux. CEPENDANT, Fanclash ne fusionnera jamais des groupes de joueurs ayant des conditions de participation différentes.`,
    "how_to_play_cancel_contests_header": `Annulation des compétitions`,
    "how_to_play_cancel_contests_info": `Fanclash se réserve le droit d'annuler les compétitions à sa discrétion, sans aucune restriction. Cela ne sera fait que si nous pensons qu'il y a un impact important sur l'intégrité des compétitions en raison de problèmes sur le site ou d'événements réels ayant un impact sur les compétitions.`,
    "how_to_play_contest_term_header": `Durée des compétitions`,
    "how_to_play_contest_term_info": `Les compétitions peuvent se dérouler en une journée, ou bien plusieurs jours, ou même plus longtemps, comme spécifié dans le règlement de chaque compétition. Les gagnants sont identifiés sur la base des critères stipulés dans le règlement du jeu.`,
    "how_to_play_squads_header": 'Équipes',
    "how_to_play_squads_rules_point_one": `Les équipes doivent être composées de 11 (onze) joueurs ou moins, en fonction du nombre de joueurs dans une même unité défensive. Des restrictions supplémentaires peuvent être mentionnées dans le règlement intérieur de la compétition`,
    "how_to_play_squads_rules_point_two": `Les positions dans lesquelles vous pouvez choisir les joueurs sont limitées à : Gardien de but (GK), Défenseur (D), Milieu de terrain (MF) et Attaquant (F). Fanclash a l'exclusivité de la décision sur l'éligibilité des positions et chaque joueur n'est éligible qu'à UNE seule des quatre positions énumérées ci-dessus.`,
    "how_to_play_game_cancel_header": `Annulations et reports`,
    "how_to_play_game_cancel_info": `Dans le cas où un tournoi réel serait annulé, reporté à une date antérieure ("préempté") ou repoussé pour quelque raison que ce soit, aucune prime ne sera accordée aux joueurs qui auront participé au tournoi ou à l'événement annulé, préempté ou repoussé.`,
    "how_to_play_prizes_header": 'Les prix',
    "how_to_play_contest_prizes_point_one": `Fanclash propose différents types de compétitions. Pour chacun d'entre eux, Fanclash annonce les prix offerts au début de la compétition.`,
    "login_header": 'Bienvenu au',
    "login_main_fantasy": 'Football Manager',
    "login_info": 'Gagnez Air Time et d’autres grands prix. Entrez votre numéro gsm ici en desous y commencez à jouer.',
    "login_input_placeholder" : 'Votre numéro gsm',
    "login_button": 'commencez à jouer',
    "login_below_button": 'Choisissez votre équipe des meilleurs joueurs d’Europe et d’Afrique',
    "login_terms_conditions": 'Ceci est un service subscription et les Termes et Conditions sont en vigueur',
    "otp_input_placeholder" : 'Entrez l’OTP',
    "otp_button": 'Confirmez',
    "otp_not_received": 'Je n’ai pas reçu mon OTP',
    "otp_resend": "Renvoyez OTP",
    "how_to_play_contest_prizes_point_two": `Les Prix ne peuvent être modifiés en raison du nombre de participants ou du montant total des droits d'inscription pour la compétitionen question`,
    "how_to_play_contest_prizes_point_three": `Les prix sont attribués aux participants qui ont accumulé le plus de points Fantasy, comme indiqué dans les modalités de la compétition, après la fin de la compétition en question. Fanclash ne déterminera jamais les gagnants en fonction du score, de l'écart de points ou des performances d'une équipe ou d'une combinaison d'équipes réelles.`,
    "how_to_play_contest_prizes_point_four": `Lorsqu'un participant gagne une compétition, le prix sera remis directement au client sous forme de temps d'appel. Pour les prix autres que le temps d'appel, les clients seront contactés en personne, conformément aux conditions générales liées à ce prix spécifique.`,
    "how_to_play_contest_prizes_point_five": `Fanclash peut proposer un jeu "gratuit" à des fins de divertissement, sans possibilité d'attribution d'un ou plusieurs prix. Cela sera clairement indiqué dès le départ`,
    "how_to_play_contest_prizes_point_six": `Le calcul des prix est basé sur les résultats des compétitions au moment du décompte final des points.`,
    "how_to_play_contest_prizes_point_seven": `Les points marqués sont basés sur les statistiques du flux de données fourni par notre fournisseur de statistiques tiers ("fournisseur de statistiques"). Bien que Fanclash s'efforce d'assurer un service de bonne réputation, il dépend uniquement des informations fournies par le fournisseur de statistiques et n'est en aucun cas responsable des données erronées reçues du fournisseur de statistiques.`,
    "how_to_play_contest_prizes_point_eight": `Par la présente, vous vous engagez à indemniser Fanclash de toute réclamation, que ce soit directement ou indirectement en raison de la mauvaise qualité des données reçues par Fanclash de ses statistiques`,
    "how_to_play_contest_prizes_point_nine": `Fanclash attend une certaine période pour confirmer le(s) gagnant(s) de la compétition après la fin de la dernière partie de la compétition, afin que le fournisseur de statistiques puisse d'abord corriger toute erreur majeure dans son système;`,
    "how_to_play_contest_prizes_point_ten": `Veuillez noter qu'une fois les prix attribués aux gagnants, les résultats des scores ne seront pas modifiés, quels que soient les ajustements officiels effectués par les ligues sportives professionnelles.`,
    "how_to_play_contest_prizes_point_eleven": `Cette situation n'est pas la même que celle où une correction doit être apportée après le règlement, en raison d'un problème avec le processus de règlement. Dans ces cas, le processus de règlement (y compris l'attribution des prix ou l'approvisionnement en temps d'antenne) peut être annulé et les jeux réinitialisés correctement`,
    "how_to_play_contest_prizes_point_twelve": `Il est impossible de changer ou de céder les prix. Tous les impôts associés à la réception ou à l'utilisation d'un prix sont de la seule responsabilité du gagnant. Dans le cas où une autorité légale attribue des prix dans le cadre d'un concours, Fanclash peut, à son entière discrétion, déterminer s'il convient d'attribuer ces prix.`,
    "how_to_play_contest_prizes_point_thirteen_point_header": 'Égalité',
    "how_to_play_contest_prizes_point_thirteen_point_one": `Dans le cas d'une égalité de points ou d'un tirage au sort dans une compétition spécifique parmi les utilisateurs de Fanclash, les Prix seront divisés de manière égale entre tous les joueurs à égalité.`,
    "how_to_play_contest_prizes_point_thirteen_point_two": `Pour départager les joueurs en cas d'égalité, le participant ayant obtenu le meilleur score individuel sera utilisé pour départager les joueurs, puis le second, et le troisième, selon le nombre de joueurs nécessaires pour départager les joueurs. Au cas où l'égalité ne serait pas brisée, le joueur qui a soumis son équipe en premier sera déclaré vainqueur.`,
    "how_to_play_notifications_header": 'Les notifications',
    "how_to_play_notifications_info": `Les vainqueurs sont généralement informés par SMS dans un délai d'un jour ouvrable. Par la présente, vous acceptez que votre nom d'utilisateur et votre pays soient publiés dans le cas où vous seriez un gagnant. Les gagnants peuvent être invités à renvoyer par courriel ou par courrier une déclaration sous serment d'éligibilité et les formulaires fiscaux appropriés. Le non-respect de ces demandes peut entraîner la disqualification du gagnant. Les notifications des prix retournées comme non distribuables peuvent entraîner une disqualification et la sélection d'un autre gagnant. Il est possible d'obtenir la liste des gagnants pour chaque période de concours en adressant une demande écrite à : Fanclash : hello@thefanclash.com`,
    "how_to_play_point_scoring_header": 'Notation des points',
    "how_to_play_point_scoring_system_header": `Système de notation de la ligue de fantasy`,
    "how_to_play_point_scoring_system_game_action": `Jeu/Action`,
    "how_to_play_point_scoring_system_daily_fantasy_league": `Ligue de Fantasy Quotidienne`,
    "how_to_play_point_scoring_system_point": 'point',
    "how_to_play_point_scoring_system_points": 'points',
    "how_to_play_point_scoring_system_minutes_played_label": `Soixante minutes jouées`,
    "how_to_play_point_scoring_system_attack_header": 'Attaquer',
    "how_to_play_point_scoring_system_attack_forward_goal_scored": `But marqué par un attaquant`,
    "how_to_play_point_scoring_system_attack_midfielder_goal_scored": `But marqué par un milieu de terrain`,
    "how_to_play_point_scoring_system_attack_defender_goal_scored": `But marqué par un défenseur`,
    "how_to_play_point_scoring_system_attack_assist": 'Aide',
    "how_to_play_point_scoring_system_attack_target_shot": `Tir sur la cible`,
    "how_to_play_point_scoring_system_attack_penalty_missed": `Coup de pied de pénalité manqué/arrêté`,
    "how_to_play_point_scoring_system_defending_header": `Défense/Discipline`,
    "how_to_play_point_scoring_system_defending_pass_intercept": `Pass intercepté`,
    "how_to_play_point_scoring_system_defending_tackle_won": `Un tackle gagnant`,
    "how_to_play_point_scoring_system_defending_clean_sheet": `Un tackle gagnant (défenseur)`,
    "how_to_play_point_scoring_system_midfielder_clean_sheet": `Un tackle gagnant (milieux de terrain)`,
    "how_to_play_point_scoring_system_defending_goal_against": `But contre (défenseurs)`,
    "how_to_play_point_scoring_system_defending_own_goal": `But contre son camp`,
    "how_to_play_point_scoring_system_defending_yellow_card": 'Carton Jaune',
    "how_to_play_point_scoring_system_defending_red_card": `Carton rouge`,
    "how_to_play_point_scoring_system_goalkeeper_header_te xt": 'Gardiens',
    "how_to_play_point_scoring_system_goalkeeping_save": 'Sauvegarder',
    "how_to_play_point_scoring_system_goalkeeping_penalty_save": `Sauvegarde du coup de pied de pénalité`,
    "how_to_play_point_scoring_system_goalkeeping_clean_sheet": `Tackle gagnant`,
    "how_to_play_point_scoring_system_goalkeeping_goal_conceded": `But contre`,
    "prizes_header": 'Les prix:',
    "prizes_promo_info": `Saisissez la chance de GAGNER du temps d’appel et d'autres GRANDS PRIX en jouant à la MTN Fantasy League!`,
    "prizes_grand_prize_header": `Grand Prix - Choix parfait`,
    "prizes_grand_prize_currency_amount": 'R50 000.00',
    "prizes_grand_prize_how_it_works_header": `Comment cela fonctionne-t-il:`,
    "prizes_grand_prize_how_it_works_point_one": `Chaque fois que vous choisissez et inscrivez une équipe, vous avez une chance de gagner R50 000.00!`,
    "prizes_grand_prize_how_it_works_point_two":`Si votre équipe marque le plus de points dans chaque position pour le tour, vous débloquerez le choix parfait`,
    "prizes_first_place_header":  `1er Prix`,
    "prizes_first_place_airtime_amount": '110.00 de temps d’appel',
    "prizes_second_tenth_place_header": `2ème - 10ème Prix`,
    "prizes_second_place_airtime_amount": '29.00 de temps d’appel',
    'active_round_play_button_label': "Jouer",
    "active_round_edit_team_button_label": "Modifier l'équipe",
    "team_builder_header": "Générateur d'équipe",
    "team_builder_matches_dropdown_header_matches": "Les Matches",
    "team_builder_matches_dropdown_header_round": "Tour",
    "team_builder_forward_position_label": "Tour",
    "team_builder_midfield_position_label": "Milieu de terrain",
    "team_builder_defender_position_label": "Défenseur ",
    "team_builder_goalkeeper_position_label": "Gardien",
    "team_builder_start_button": "Démarrer ",
    "team_builder_submit_button": "Soumettre ",
    "team_builder_captain_popup_header": "Sélectionnez un capitaine pour obtenir des POINTS DOUBLÉS de leur part.",
    "team_builder_captain_popup_submit_button": 'Soumettre',
    "team_builder_popup_cancel_button": 'Annuler',
    "team_builder_auto_team_builder_button": " Générateur d'équipe",
    "team_selection_header": "Générateur d'équipe",
    "team_selection_select_position": "Sélectionnez {0}",
    "team_selection_forward_position_label": "Attaquant",
    "team_selection_midfield_position_label": "Milieu de terrain",
    "team_selection_defender_position_label": "Défenseur ",
    "team_selection_goalkeeper_position_label": "Gardien",
    "team_selection_table_column_rank_label": "Classement",
    "team_selection_table_column_player_label": "Joueur",
    "team_selection_table_column_previous_round_label": "Tour précédent",
    "team_selection_table_column_total_points_label": "Nombre total de points",
    "team_selection_table_column_average_points_label": `Points moyens`,
    "team_selection_next_button_label": "Suivant",
    "change_captain_team_builder_button": "Changement de capitaine",
    "subscription_header": "Confirmer l'abonnement",
    "subscription_confirm": "Je confirme mon abonnement de 50 XAF (ou un montant inférieur).",
    "subscription_manage": "Cela peut ensuite être géré dans les paramètres de votre profil.",
    "subscribe_button": "S'abonner",
    "subscription_terms_conditions": "En cliquant sur \"S'abonner\", j'accepte les termes et conditions de Fantasy Football",
    "upcoming_rounds_entered_all_rounds" : 'Vous avez participé à tous les tours à venir. Pour voir les équipes inscrites, allez dans Mes rondes',
    "upcoming_rounds_entered_no_rounds" : 'Vous n\'avez encore participé à aucun tour. Pour constituer votre équipe, allez sur Play',
    "round_leaderboard_table_player_username_default" : 'Joueur',
    "team_builder_successfully_submitted_one": 'Merci coach ! Votre équipe a été soumise avec succès le',
    "team_builder_successfully_submitted_two": 'Fantasy League',
    "team_builder_successfully_submitted_my_rounds_button": 'Aller à Mes tournées',
    "settings_season_points_header": "SEASON POINTS",
    "invalid_otp": 'non valide OTP',
    "expired_otp": 'Votre OTP a expiré, veuillez cliquer sur "Renvoyer OTP" pour recevoir un nouvel OTP.',
    "otp_limit": 'Vous avez fait deux tentatives incorrectes, veuillez cliquer sur "Renvoyer OTP" pour recevoir un nouveau otp.',
    "otp_used": 'Cet OTP a déjà été utilisé, veuillez cliquer sur "Renvoyer OTP" pour recevoir un nouvel OTP.',
    "login_info_incorrect_msisdn": 'Input invalid, must contain only numbers.',
    "login_info_incorrect_msisdn_length": 'Length of number is incorrect.',
    "login_terms_conditions_text_one": 'Ceci est un service avec abonnement et des',
    "login_terms_conditions_text_two": 'Termes et Conditions',
    "login_terms_conditions_text_three": 'sont en vigueur.',
}
