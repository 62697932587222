<template>
  <div class="body-container" v-if="!isLoading">
    <div class="d-flex flex-row align-items-center pb-2">
      <img class="pt-3 col-10 header-navbar-logo"
           :src="require(`../../brand/${brandConfig.brand}/assets/main-fantasy-header.png`)">
      <div v-if="brandConfig.brand === 'ethiotel-et-amh' || brandConfig.brand === 'ethiotel-et-en'"
           class="d-flex justify-content-center align-items-center border-1 primary-bg-colour language">
        <span @click="onLanguageClicked" class="secondary-text"
              v-if="brandConfig.brand === 'ethiotel-et-amh' || brandConfig.brand === 'ethiotel-et-en'">{{
            getLanguage()
          }}</span>
      </div>
    </div>
    <div class="d-flex flex-column text-center justify-content-center primary-bg-colour primary-text header-container">
      {{ translationsFormatter('subscription_header') }}
    </div>
    <div
        class="d-flex flex-column text-center justify-content-center align-items-center secondary-bg-colour primary-text main-container">
      <img class="fantasy-logo" :src="require(`../../brand/${brandConfig.brand}/assets/fantasy-logo-icon.png`)">
      <div class="pb-1 ">{{ translationsFormatter('login_main_fantasy') }}</div>
    </div>
    <div class="pt-2 background-behind">
      <br>
    </div>
    <div v-if="brandConfig.brand !== 'mtn-zam-en'" class="d-flex flex-column justify-content-center align-items-center text-center subscribe-container">
      <div class="pt-3 pb-1 col-10 subscribe-text">{{ translationsFormatter('subscription_confirm') }}</div>
      <div class="pt-2 pb-1 col-10 subscribe-text">{{ translationsFormatter('subscription_manage') }}</div>
      <button @click="onSubscribeClicked('Daily')"
              class="col-10 mt-5 mb-3 text-center primary-bg-colour primary-text subscribe-button">
        {{ translationsFormatter('subscribe_button') }}
      </button>
      <div v-if="failedSubscription" class="col-10 pt-3 pb-4 login-info-text text-red">
        {{ translationsFormatter('subscription_failed') }}
      </div>
      <div class="col-10 pt-3 pb-4 login-info-text">{{ translationsFormatter('subscription_terms_conditions') }}</div>
    </div>
    <div v-if="brandConfig.brand === 'mtn-zam-en'" class="d-flex flex-column justify-content-center align-items-center text-center subscribe-container">
      <div class="pt-3 pb-1 mb-3 col-10 subscribe-text"><b>{{ translationsFormatter('subscription_confirm_multi_tier') }}</b></div>
      <button @click="onSubscribeClicked('Monthly')"
              class="col-10 mt-1 mb-3 text-center primary-bg-colour primary-text subscribe-button">
        {{ translationsFormatter('subscribe_button_monthly') }}
      </button>
      <button @click="onSubscribeClicked('Weekly')"
              class="col-10 mt-1 mb-3 text-center primary-bg-colour primary-text subscribe-button">
        {{ translationsFormatter('subscribe_button_weekly') }}
      </button>
      <button @click="onSubscribeClicked('Daily')"
              class="col-10 mt-1 mb-3 text-center primary-bg-colour primary-text subscribe-button">
        {{ translationsFormatter('subscribe_button_daily') }}
      </button>
      <div v-if="failedSubscription" class="col-10 pt-3 pb-4 login-info-text text-red">
        {{ translationsFormatter('subscription_failed') }}
      </div>
      <div class="col-10 pt-3 pb-4 login-info-text">{{ translationsFormatter('subscription_terms_conditions') }}</div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import UserDataService from "@/services/userDataService";
import getServiceId from "@/functions/getServiceId";
import {setNewBrandConfigLanguage} from "@/functions/setBrandConfig";
import {environmentConfig} from "@/config/enviromentConfig";

export default {
  name: "ConfirmSubscription",
  data() {
    return {
      failedSubscription: false,
    }
  },
  computed: {
    ...mapState(['user', 'brandConfig', 'isLoading'])
  },
  methods: {
    ...mapMutations(['setUser', 'setIsLoading', 'setUserStats']),
    redirectSubscriptionZambia(scheme) {
      // if (scheme === 'Daily') return window.open(`http://www.mtn-zm-webfun.com/ZBF-ZMMTN-UPSTR/fantasyfootball1-en-doi-web/?HEKeyword=FANCLASH_PORTAL_5&utm_source=fanclash_portal&utm_medium=free&trfsrc=fanclash`, '_self');
      // if (scheme === 'Weekly') return window.open(`http://www.mtn-zm-webfun.com/ZBF-ZMMTN-UPSTR/fantasyfootball1-weekly-en-doi-web/?HEKeyword=FANCLASH_PORTAL_1&utm_source=fanclash_portal&utm_medium=free&trfsrc=fanclash`, '_self');
      // if (scheme === 'Monthly') return window.open(`http://www.mtn-zm-webfun.com/ZBF-ZMMTN-UPSTR/fantasyfootball1-monthly-en-doi-web/?HEKeyword=FANCLASH_PORTAL_3&utm_source=fanclash_portal&utm_medium=free&trfsrc=fanclash`, '_self');
    },
    redirectForAirtelNigeria() {
      return window.open(`http://ng-airtel-web.upp.st/NAF-NGAIR-RGS/fantasygames1-en-doi-web/?HEKeyword=NAF_MLD_PORTAL&utm_source=melodi&utm_medium=portal&utm_term=NAF_MLD_PORTAL&utm_content=fantasygames1&utm_campaign=NAF_MLD_PORTAL-melodi-fantasygames1-all`, '_self')
    },
    async onSubscribeClicked(scheme) {
      if (this.brandConfig.brand === 'mtn-zam-en') return this.redirectSubscriptionZambia(scheme);
      if (this.brandConfig.brand === 'air-nga-en') return this.redirectForAirtelNigeria();
      this.failedSubscription = false;
      this.setIsLoading(true);
      const serviceId = getServiceId();
      const response = await UserDataService.subscribe(this.user.msisdn, this.user.token, `${this.brandConfig.revenuePartnerId}`, serviceId, scheme);
      if (response.data.result) {
        localStorage.setItem('token', this.user.token);
        const subscribedUser = await UserDataService.getUserStatus(this.user.token);
        const stats = await UserDataService.getUserStats(subscribedUser.data.msisdn);
        this.setUser(subscribedUser.data);
        this.setUserStats(stats.data);
        this.invalidOtp = false;
        this.setIsLoading(false);
        this.$router.push(`/profile`);
      }
      if (response.data.errorDescription) {
        this.setIsLoading(false);
        this.failedSubscription = true
      }
    },
    onLanguageClicked() {
      setNewBrandConfigLanguage(this.brandConfig.brand)
      if (this.brandConfig.brand === 'ethiotel-et-en') this.language = 'AMH'
      if (this.brandConfig.brand === 'ethiotel-et-amh') this.language = 'EN'
    },
    getLanguage() {
      if (this.brandConfig.brand.includes('en')) return 'EN'
      if (this.brandConfig.brand.includes('amh')) return 'AMH'
    }
  },
  beforeMount() {
    if (this.brandConfig.brand === 'ethiotel-et-amh') this.language = 'AMH'
    if (this.brandConfig.brand === 'ethiotel-et-en') this.language = 'EN'
  },
}
</script>

<style scoped>
.language {
  padding: 5px;
  margin-top: 15px;
}

.text-red {
  color: red;
}

.body-container {
}

.header-container {
  font-weight: 500;
  height: 40px;
  font-size: 14px;
}

.main-container {
  font-weight: 500;
  height: 200px;
  font-size: 28px;
  border-radius: 0 0 18px 18px;
  z-index: 1;
}

.fantasy-logo {
  height: 120px;
}

.background-behind {
  background-color: #e8e8e8;
  margin-top: -15px;
  z-index: -1;
  position: relative;
}

.subscribe-container {
  background-color: #e8e8e8;
  padding-bottom: 50px;
}

.login-info-text {
  font-weight: 500;
  font-size: 14px;
}


.subscribe-button {
  height: 45px;
  border-radius: 10px;
  border: 0;
  font-size: 13px;
  font-weight: 600;
}

.subscribe-text {
  font-weight: 500;
  font-size: 14px;
}

</style>
